<template>
  <div id="main">
    <div class="bg_text">
      buyer<br />persona
    </div>
    <div class="container">
      <router-view/>
    </div>
  </div>
  <persona-footer></persona-footer>
</template>

<script>
import PersonaFooter from "@/components/PersonaFooter";

export default {
  name: 'App',
  components: {PersonaFooter},
}
</script>

<style>

*{
  margin: 0px;
}

body{
  background-color: #F3F2E9;
}

.container{
  margin: 0px auto;
  max-width: 1400px;
}

h1{
  margin-top: 0px;
  font-size: 48px;
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 16px;
}

h2{
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 42px;
}

h3{
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 20px;
}

p{
  line-height: 1.75;
}

#app {
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #444444;
  background-color: #F3F2E9;
  padding: 0px;
}

a{
  color: var(--primary-color);
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

/* Question layout */
.question_container{
  display: flex;
  margin-bottom: 100px;
}

.question_container .question_answers{
  position: relative;
  flex: 0 0 70%;
  background-color: transparent;
  padding-right: 35px;
}

.question_container .question_answers:after{
  content: "";
  position: absolute;
  top: 10%;
  right: 0px;
  border-right: 1px solid #C1C1C1;
  height: 80%;
  width: 1px;
}

.question_container .question_answers .answer_field{
  margin-bottom: 40px;
}

.question_container .question_info{
  flex: 0 0 30%;
  padding-left: 35px
}

/* Input */
input{
  width: 100%;
}

.p-float-label{
  margin-top: 26px;
}

/* Buttons */
.p-selectbutton .p-button label{
  margin-left: 5px;
}

/*.communication_list.persona_overview .p-selectbutton.p-buttonset .p-button,*/
/*.social_network_list.persona_overview .p-selectbutton.p-buttonset .p-button{*/
/*  width: 50%;*/
/*  border-right: 1px solid #304562;*/
/*  border-radius: 3px;*/
/*}*/

/* Radio buttons */
.rb_choices{
  display: flex;
}

.p-field-radiobutton{
  padding: 5px 10px;

}

.p-field-radiobutton label{
  margin-left: 10px;
}

/* Checkbox list */
.cb_list{
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
}

.p-field-checkbox{
  flex: 0 0 50%;
  padding: 0px 20px 0px 0px;
  margin-bottom: 10px;
}

.p-field-checkbox label{
  margin-left: 20px;
}

/* Form inputs */
.p-inputtextarea{
  width: 100%;
}

#main{
  position: relative;
  overflow: hidden;
  padding: 0px 20px;
}

#main .container{
  position: relative;
  z-index: 10;
}

.bg_text{
  position: absolute;
  z-index: 1;
  text-align: right;
  right: 0px;
  bottom: -14px;
  color: #DEDEDE;
  font-size: 221px;
  font-weight: 600;
  line-height: 180px;
}

</style>
