<template>
  <footer>
    <div class="container">
      <div class="footer_info">
        <div class="footer_col">
          <img class="footer_logo" alt="CPH Digital Tool" src="/gfx/cphd_tool_logo_grey.png" />
        </div>
        <div class="footer_col">
          <h4>CPH digital ApS</h4>
          <p>
            Østergade 17, 2. sal.<br />
            1100 København K<br />
            Danmark<br />
            CVR: DK33586752<br />
            <a href="https://www.cphdigital.dk/persondatapolitik/" target="_blank">Vores persondatapolitik</a><br />
          </p>
        </div>
        <div class="footer_col">
          <h4>Menu</h4>
          <ul>
            <li><a href="https://www.cphdigital.dk/specialer/" target="_blank">Specialer</a></li>
            <li><a href="https://www.cphdigital.dk/cases/" target="_blank">Cases</a></li>
            <li><a href="https://www.cphdigital.dk/blog/" target="_blank">Blog</a></li>
            <li><a href="https://www.cphdigital.dk/kontakt-os/" target="_blank">Kontakt os</a></li>
          </ul>
        </div>
        <div class="footer_col">
          <h4>Start en samtale</h4>
          <p>Tlf: <a href="tel:+4526743204">(+45) 2674-3204</a></p>
          <div class="footer_social">
            <a href="https://www.facebook.com/CPHdigital/" target="_blank"><i class="pi pi-facebook" data-v-43989d5d=""></i></a>
            <a href="https://www.linkedin.com/company/cph-digital/" target="_blank"><i class="icon-linkedin" data-v-43989d5d=""></i></a>
          </div>
          <img class="itb_logo" src="gfx/medlem-af-it-branchen-logo-white.png">
        </div>
      </div>
      <div class="footer_copy">
        <p>Copyright© 2011-2021, CPH digital ApS</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "PersonaFooter"
}
</script>

<style scoped>
  footer{
    background-color: #2D2D2D;
  }

  footer img{
    max-width: 100%;
  }

  .footer_info{
    display: flex;
    padding-top: 88px;
    padding-bottom: 100px;
  }

  .footer_info .footer_col{
    flex: 0 0 25%;
    max-width: 25%;
  }

  .footer_info h4{
    color: #ffffff;
    font-weight: 500;
    font-size: 18px;
  }

  .footer_info p{
    line-height: 32px;
    color: #9B9B9B;
  }

  .footer_info a{
    color: #ffffff;
  }

  .footer_info ul{
    padding-left: 12px;
  }

  .footer_info ul li{
    list-style-position: outside;
    line-height: 32px;
  }

  .footer_info ul li::marker{
    color: #9B9B9B;
    font-size: 60%;
    margin-right: 10px;
  }

  .footer_info .itb_logo{
    max-width: 120px;
    margin-top: 26px;
  }

  .footer_copy{
    border-top: 1px solid #5B5B5B;
    font-size: 16px;
    color: #5B5B5B;
    padding: 26px 0px;
  }

  .footer_social{
    margin-top: 16px;
  }

  .footer_social i{
    font-size: 24px;
    color: #5B5B5B;
    margin-right: 20px;
  }

  .footer_logo{
    max-width: 58%;
  }

  @media screen and (max-width: 767px){
    footer{
      padding: 0px 20px;
    }
    .footer_info{
      flex-wrap: wrap;
    }

    .footer_info .footer_col{
      flex: 0 0 50%;
      max-width: 50%;
      margin-bottom: 15px;
    }
  }
</style>
