import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/create-avatar',
    name: 'CreateAvatar',
    component: () => import( '../views/questions/CreateAvatar.vue'),
    meta: {
      step: 1
    }
  },
  {
    path: '/demographic-traits',
    name: 'DemographicTraits',
    component: () => import( '../views/questions/DemographicTraits.vue'),
    meta: {
      step: 2
    }
  },
  {
    path: '/business',
    name: 'TheirBusiness',
    component: () => import( '../views/questions/Business.vue'),
    meta: {
      step: 3
    }
  },
  {
    path: '/career',
    name: 'TheirCareer',
    component: () => import( '../views/questions/Career.vue'),
    meta: {
      step: 4,
    }
  },
  {
    path: '/characteristics-of-job',
    name: 'CharacteristicsOfJob',
    component: () => import( '../views/questions/CharacteristicsOfJob.vue'),
    meta: {
      step: 5,
    }
  },
  {
    path: '/how-they-work',
    name: 'HowTheyWork',
    component: () => import( '../views/questions/HowWork.vue'),
    meta: {
      step: 6,
    }
  },
  {
    path: '/consumption-habits',
    name: 'ConsumptionHabits ',
    component: () => import( '../views/questions/ConsumptionHabits.vue'),
    meta: {
      step: 7,
      lastStep: true
    }
  },
  {
    path: '/persona-overview',
    name: 'PersonaOverview',
    component: () => import( '../views/PersonaOverview.vue'),
  },
  {
    path: '/persona-overview/:personaId',
    name: 'PersonaOverviewGetData',
    component: () => import( '../views/PersonaOverview.vue'),
  },
  {
    path: '/saved-persona',
    name: 'SavedOverview',
    component: () => import( '../views/SavedPersona.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
