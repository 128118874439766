import { createStore } from "vuex";
import state from "@/store/state";
import actions from "@/store/actions";
import mutations from "@/store/mutations";


const store = createStore({
    state,
    getters: {

    },
    actions,
    mutations
})

export default store;