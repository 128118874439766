<template>
  <img class="home_logo" alt="CPH Digital" src="/gfx/cphd_tool_logo_red.png"/>
  <div class="home">
    <img src="/gfx/personas-intro.png" alt="Personas" class="intro-image"/>
    <h1>Opret detaljerede buyer<br />personas til din virksomhed</h1>
    <h2>Opret en Buyer Persona, som hele din virksomhed kan<br /> bruge til at markedsføre, sælge og servicere ud fra.</h2>
    <Button @click="gotToFirstStep" class="btn_start">Byg min Persona</Button>
    <div class="info_row">
      <div class="info_col">
        <div class="persona-q">
          <div class="icon">
            <img src="/gfx/Hvad-er-en-buyer-persona.png" />
          </div>
          <div class="description">
            <h3>Hvad er en buyer persona?</h3>
            <p>
              En buyer persona er en semi-fiktiv repræsentation af din ideelle kunde baseret på markedsundersøgelser og
              reelle data om dine eksisterende kunder.
            </p>
          </div>
        </div>
      </div>
      <div class="info_col">
        <div class="persona-q">
          <div class="icon">
            <img src="/gfx/Hvorfor-bruge-en-buyer-persona.png" />
          </div>
          <div class="description">
            <h3>Hvorfor bruge en buyer persona?</h3>
            <p>
              Buyer personaer giver struktur og kontekst til din virksomhed, hvilket gør det lettere at kortlægge indhold,
              tildele tid til dit team og ressourcer og opnå afstemning på tværs af din organisation.
            </p>
          </div>
        </div>
      </div>
      <div class="info_col">
        <div class="persona-q">
          <div class="icon">
            <img src="/gfx/Hvordan-skal-jeg-bruge-buyer-personaer.png" />
          </div>
          <div class="description">
            <h3>Hvordan skal jeg bruge buyer personaer?</h3>
            <p>
              At lære om din ideelle kunde - deres udfordringer, deres mål, deres demografiske træk osv. - hjælper dig med
              at sætte en strategi, der sigter mod at tiltrække de mest værdifulde besøgende, kundeemner og kunder til din
              virksomhed.
            </p>
          </div>
        </div>
      </div>
      <div class="info_col">
        <div class="persona-q">
          <div class="icon">
            <img src="/gfx/Hvordan-opretter-jeg-en-persona.png" />
          </div>
          <div class="description">
            <h3>Hvordan opretter jeg en persona?</h3>
            <p>
              Bug vores værktøj. Udfyld alle felterne. Hvis du er i tvivl om de enkelte trin har vi skrevet en lille
              hjælpetekst til højre, som forklare hvad det enkelte trin skal bruges til, og hvorfor det er vigtigt
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {useRouter} from "vue-router";

export default {
  name: 'Home',
  components: {},
  setup(){

    const router = useRouter();

    function gotToFirstStep(){
      router.push('/create-avatar')
    }

    return{
      gotToFirstStep
    }
  }
}
</script>
<style scoped>
  h1{
    font-size: 56px;
    text-decoration: underline;
    margin-bottom: 16px;
  }

  h2{
    font-size: 24px;
    font-weight: 400;
    margin: 16px 0px;
  }

  h3{
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 12px;
    color: #FF2A55;
  }

  .home{
    position: relative;
    text-align: center;
  }

  .home .intro-image{
    max-width: 727px;
    margin-bottom: 16px;
  }

  .home_logo{
    max-width: 72px;
    margin-top: 45px;
    margin-bottom: -45px;
  }

  .info_row{
    max-width: 972px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 164px;
    text-align: left;
  }

  .info_row .info_col{
    line-height: 28px;
  }

  .btn_start{
    font-size: 18px;
    margin-bottom: 62px;
    padding: 1rem 3.25rem;
  }

  .bg_text{
    position: absolute;
    z-index: 1;
    text-align: right;
    right: 0px;
    bottom: -14px;
    color: #DEDEDE;
    font-size: 221px;
    font-weight: 600;
    line-height: 180px;
  }

  .persona-q{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 70px;
  }

  .persona-q .icon{
    flex: 0 0 148px;
    max-width: 148px;
    text-align: center;
    margin-right: 15px;
  }

  .persona-q .icon img{
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }

  .persona-q .description{
    flex: 0 1 735px;
    max-width: 735px;
  }

  @media screen and (max-width: 980px){
    .info_row .info_col {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .home .intro-image{
      max-width: 100%;
      margin-bottom: 16px;
    }
  }

  @media screen and (max-width: 767px){
    .home_logo{
      margin-top: 14px;
      margin-bottom: 5px;
    }

    .info_row .info_col {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .persona-q{
      display: block;
    }

    .persona-q .icon{
      max-width: 100%;
      margin-bottom: 15px;
    }
  }

</style>
