const actions = {
    updateSteps: ({commit}, payload) => {
        commit('updateSteps', payload)
    },
    setPersonaName: ({commit}, payload) => {
        commit('setPersonaName', payload)
    },
    setAvatar: ({commit}, payload) => {
        commit('setAvatar', payload)
    },
    setPersonaAge: ({commit}, payload) => {
        commit('setPersonaAge', payload)
    },
    setLevelOfSchool: ({commit}, payload) => {
        commit('setLevelOfSchool', payload)
    },
    setIndustry: ({commit}, payload) => {
        commit('setIndustry', payload)
    },
    setSizeOfOrganization: ({commit}, payload) => {
        commit('setSizeOfOrganization', payload)
    },
    setJobTitle: ({commit}, payload) => {
        commit('setJobTitle', payload)
    },
    setJobMeasured: ({commit}, payload) => {
        commit('setJobMeasured', payload)
    },
    setWhoReportTo: ({commit}, payload) => {
        commit('setWhoReportTo', payload)
    },
    setGoalsOrObjectives: ({commit}, payload) => {
        commit('setGoalsOrObjectives', payload)
    },
    updateListBiggestChallenges: ({commit}, payload) => {
        commit('updateListBiggestChallenges', payload)
    },
    setPersonaBiggestChallenges: ({commit}, payload) => {
        commit('setPersonaBiggestChallenges', payload)
    },
    updatePersonaBiggestChallenges: ({commit}, payload) => {
        commit('updatePersonaBiggestChallenges', payload)
    },
    setJobResponsibilities: ({commit}, payload) => {
        commit('setJobResponsibilities', payload)
    },
    updateListTools: ({commit}, payload) => {
        commit('updateListTools', payload)
    },
    setPersonaTools: ({commit}, payload) => {
        commit('setPersonaTools', payload)
    },
    updatePersonaTools: ({commit}, payload) => {
        commit('updatePersonaTools', payload)
    },
    setPersonaCommunicate: ({commit}, payload) => {
        commit('setPersonaCommunicate', payload)
    },
    setHowGainInformationForJob: ({commit}, payload) => {
        commit('setHowGainInformationForJob', payload)
    },
    setPersonaSocialNetwork: ({commit}, payload) => {
        commit('setPersonaSocialNetwork', payload)
    },
    setDisplayHubspotForm: ({commit}, payload) => {
        commit('setDisplayHubspotForm', payload)
    },
    setSavedPersonaId: ({commit}, payload) => {
        commit('setSavedPersonaId', payload)
    },
    setSavedPersonaData: ({commit}, payload) => {
        commit('setPersonaName', {name: payload.personaName})
        commit('setAvatar', {avatar: payload.avatar})
        commit('setPersonaAge', {age: payload.personaAge})
        commit('setLevelOfSchool', {level: payload.levelOfSchool})
        commit('setIndustry', {industry: payload.industry})
        commit('setSizeOfOrganization', {size: payload.sizeOfOrganization})
        commit('setJobTitle', {title: payload.jobTitle})
        commit('setJobMeasured', {measured: payload.jobMeasured})
        commit('setWhoReportTo', {title: payload.whoReportTo})
        commit('setGoalsOrObjectives', {goalsOrObjects: payload.goalsOrObjectives})
        commit('setPersonaBiggestChallenges', {biggestChallenges: payload.personaBiggestChallenges})
        commit('setJobResponsibilities', {responsibilities: payload.jobResponsibilities})
        commit('setPersonaTools', {tools: payload.personaTools})
        commit('setPersonaCommunicate', {communicate: payload.personaCommunicate})
        commit('setHowGainInformationForJob', {description: payload.howGainInformationForJob})
        commit('setPersonaSocialNetwork', {social: payload.personaSocialNetwork})
    }
}

export default actions
