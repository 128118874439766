import { createApp } from 'vue'
import App from './App.vue'
import store from './store/store'
import router from './router'
import PrimeVue from "primevue/config";
import axios from 'axios'
import VueAxios from 'vue-axios'

// import 'primevue/resources/themes/vela-blue/theme.css'
import './assets/css/primevue-theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import './assets/css/fontello.css'

import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import RadioButton from 'primevue/radiobutton';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import SelectButton from 'primevue/selectbutton';
import Card from 'primevue/card';
import Divider from 'primevue/divider';
import Textarea from 'primevue/textarea'
import Dialog from 'primevue/dialog';
import Tooltip from 'primevue/tooltip';

const app = createApp(App).use(router)

//Guard prevent nav to substeps
// router.beforeEach((to, from, next) => {
//   console.log('To: ', to.path);
//   console.log('From: ', from);
//   console.log('Next: ', next);
//
//   const guardetPath = [
//     '/demographic-traits'
//   ]
//
//   if(guardetPath.includes(to.path) && !store.state.hasSteps.includes(to.path)){
//     next(false)
//   } else {
//     next();
//   }
//
// })

app.use(VueAxios, axios)
app.use(PrimeVue)
app.component('InputText', InputText);
app.component('Button', Button);
app.component('RadioButton', RadioButton);
app.component('Dropdown', Dropdown);
app.component('Checkbox', Checkbox);
app.component('SelectButton', SelectButton);
app.component('Card', Card);
app.component('Divider', Divider);
app.component('Textarea', Textarea)
app.component('Dialog', Dialog)
app.directive('tooltip', Tooltip);
app.use(store);
app.mount('#app');
